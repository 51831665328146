import { Controller } from 'stimulus'

export default class ConnectorIndex extends Controller {
  toggleUnregisteredInvites() {
    let queryString = window.location.search
    let urlParams = new URLSearchParams(queryString)
    urlParams.set('show_unregistered_invites', (urlParams.get('show_unregistered_invites') === null || urlParams.get('show_unregistered_invites') == 'true') ? 'false' : 'true')

    window.location = '/connectors?' + urlParams.toString();
  }
}
